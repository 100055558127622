import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyALKDjgHBtd4YWVM984rBcYuqv2Hdn_MlQ',
  authDomain: 'imvialex.firebaseapp.com',
  databaseURL: 'https://imvialex.firebaseio.com',
  projectId: 'imvialex',
  storageBucket: 'imvialex.appspot.com',
  messagingSenderId: '452948049149',
  appId: '1:452948049149:web:bd21110646d4db9c265294',
  measurementId: 'G-53X6J5M9HR'
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}
